import React, {Fragment} from "react";
import { Section } from '@jfrk/react-heading-levels';
import { WPBlockController } from "../WPBlockController";

export function WPBlocks({ blocks, skipSection, hasFooterCards = false, ...restProps }) {
  const Wrap = skipSection ? Fragment : Section;
  const nrOfItems = blocks.length;
  return (
    <Wrap>
      {blocks.map((block, index) => (
        <WPBlockController
          key={index}
          block={block}
          isLastBlock={index === (nrOfItems - 1)}
          hasFooterCards={hasFooterCards}
          {...restProps}
        />
      ))}
    </Wrap>
  );
}
