import { FluidImage } from "../../@whitespace/gatsby-theme-wordpress/components/Image";
import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import cx from "classnames";
import parse from "html-react-parser";
import React from "react";

import extractPathFromUrl from "../../utils/extractPathFromUrl";

import "./PushSquare.scss";

export default function PushSquare({ alignment, items }) {
  const ConditionallyWrapWithLink = ({ condition, wrapper, children }) => {
    if (condition) {
      return wrapper(children);
    }
    return children;
  };

  return (
    <div
      className={cx(
        "push-square",
        alignment && `push-square--align-${alignment}`,
      )}
    >
      <div className="push-square__wrapper">
        {items?.map(({ media, link, body, title }, index) => {
          media = {
            ...media,
            width: 220,
            height: 220,
            caption: null,
          };
          return (
            <div className="push-square__item" key={index}>
              <div>
                {link &&
                  <ConditionallyWrapWithLink
                    condition={link}
                    wrapper={(children) =>
                      link.target ? (
                        <a
                          className="push-square__link"
                          href={extractPathFromUrl(link.url)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      ) : (
                        <Link
                          className="push-square__link"
                          to={extractPathFromUrl(link.url)}
                        >{children}</Link>
                      )
                    }
                  ><span className="visually-hidden">{title}</span></ConditionallyWrapWithLink>
                }
                <FluidImage {...media} className="push-square__item-media" />
                {title && (
                  <p className="push-square__item-title meta">{title}</p>
                )}
                {body && (
                  <div className="push-square__item-body">{parse(body)}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
