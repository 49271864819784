import { SearchHit } from "@whitespace/gatsby-theme-search/src/components/SearchHit/SearchHit";
import React from "react";

export function SearchHits({ hits, ...restProps }) {
  return (
    <ul {...restProps}>
      {hits.map((hit, index) => (
        <SearchHit {...hit} key={index} />
      ))}
    </ul>
  );
}
