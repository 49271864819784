import React, {Fragment} from "react";
import cx from "classnames";
import { WPBlocks } from "../WPBlocks";
import "./WPCoreListBlock.scss";

export function WPCoreListBlock({ block: { innerBlocks, attributes } }) {
  const { ordered, values } = attributes;

  const blocks = [...innerBlocks].map((block, index) => {
      return {
          ...block,
          ...innerBlocks[index],
      };
  });

  const ListTag = ordered ? 'ol' : 'ul';

  const classes = cx(
    'list-block',
    {'list-block--ordered': ordered},
    {'list-block--unordered': !ordered},
  );

  if(blocks.length > 0) {
    return (
      <ListTag className={classes}>
        <WPBlocks
          blocks={blocks}
          skipSection={true}
        />
      </ListTag>
    );
  } else {
    return (
      <ListTag
        className={classes}
        dangerouslySetInnerHTML={{__html: values}}
      />
    );
  }
}
