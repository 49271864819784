import React from "react";
import cx from "classnames";
import { MediaElement } from "../../../../components/MediaElement";
import "./WPCoreEmbedBlock.scss";

const WPCoreEmbedBlock = (props) => {
    const { block: { attributes: { width, url, align }, size } } = props;

    const style = {
        ...(width && {width: width}),
    };

    const video = {
        mediaItemUrl: url,
        autoPlay: false,
        controls: true,
    };

    const classes = cx(
        "embed-block",
        { [`embed-block--${size}`]: size && !width},
        'mt-xl',
        'mb-xl',
        'wrapper',
        {'wrapper--align-default': !align},
        {[`wrapper--align-${align}`]: align},
    );

    return (
        <div className={classes} style={style}>
            <MediaElement
                video={video}
            />
        </div>
    );
};

WPCoreEmbedBlock.defaultProps = {
    block: {
        attributes: {
            width: null,
        },
        size: 'full',
    },
};

export default WPCoreEmbedBlock;