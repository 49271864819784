export default function getSocialMediaItem(channel, pageLink) {
  let item = {
    name: "",
    link: "",
    icon: "",
  };
  switch (channel) {
    case "twitter":
      item.name = "Twitter";
      item.icon = "social-twitter";
      item.link = `http://twitter.com/share?url=${pageLink}`;
      break;
    case "facebook":
      item.name = "Facebook";
      item.icon = "social-facebook";
      item.link = `https://www.facebook.com/sharer/sharer.php?u=${pageLink}`;
      break;
  }

  return item;
}
