import StateSearchParamsProvider from "@whitespace/gatsby-theme-search/src/components/StateSearchParamsProvider";
import { SearchHandler } from "@whitespace/gatsby-theme-search/src/hooks/search";
import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
// import { SiteLayoutContext } from "../../@whitespace/gatsby-theme-wordpress/components/SiteLayout/SiteLayout";
import { useMenu } from "../../@whitespace/gatsby-theme-wordpress/hooks/menus";
import cx from "classnames";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import PrimaryNavigation from "./../PrimaryNavigation/PrimaryNavigation";
import SecondaryNavigation from "./../SecondaryNavigation/SecondaryNavigation";
import HeaderLogo from "./HeaderLogo";
import { HeaderSearchView } from "./HeaderSearchView";

import "./Header.scss";

export default function Header({ translations, ...restProps }) {
  const [currentView, setCurrentView] = useState(null);
  const [sticky, setSticky] = useState(false);
  // const [, setSiteLayoutContext] = useContext(SiteLayoutContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();

  const headerRef = useRef();

  const onViewToggleClick = (view) => {
    setCurrentView(currentView === view ? null : view);
  };

  // useEffect(() => {
  //   setSiteLayoutContext((state) => {
  //     return { ...state, menuOpen: !!currentView };
  //   });
  // }, [currentView]);

  useEffect(() => {
    // toggle header open class
    document.documentElement.classList.toggle(
      "header-open",
      currentView != null,
    );

    // function to close menu on click outside of header
    const handleClickOutside = (el) => {
      if (!currentView) return;

      if (headerRef.current && !headerRef.current.contains(el.target)) {
        onViewToggleClick(currentView);
      }
    };

    // toggle header sticky class
    const handleScroll = () => {
      let scrollPos = window.scrollY;
      let elHeight = headerRef.current ? headerRef.current.scrollHeight : 0;

      setSticky(scrollPos > elHeight ? true : false);
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("click", handleClickOutside);

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [currentView]);

  const helpLinksNavigationItems = useMenu(
    currentLanguage === "sv" ? `help_links` : `help_links___${currentLanguage}`,
  );

  const socialNavigationItems = useMenu(
    currentLanguage === "sv" ? `social` : `social___${currentLanguage}`,
  );

  const otherLanguage = currentLanguage === "sv" ? "en" : "sv";
  const siteUrl = currentLanguage === "sv" ? "/" : `/${currentLanguage}/`;
  const translationUrl = currentLanguage === "sv" ? `/${otherLanguage}/` : "/";

  const languages = ["sv", "en"];

  const classes = cx(
    "header",
    {"header--sticky": sticky},
    {[`header--${currentView}`]: currentView},
  );

  return (
    <header
      className={classes}
      id="header"
    >
      <nav className="header__translations" aria-label={t("Translations")} lang={otherLanguage}>
        {languages.map((lang, index) => {
          const code = lang.toUpperCase();
          const isCurrent = currentLanguage === lang;
          if(isCurrent) {
            return (
              <div className="header__translation" key={index}>
                <span className="visually-hidden">{t("Current language is")}: </span>{code}
              </div>
            );
          } else {
            const index = translations?.map(t => t.language.code).indexOf(code);
            const url = index > -1 ? translations[index].link : translationUrl;
            return (
              <Link className="header__translation" to={url} key={index}>
                <span className="visually-hidden">{t("Change language to")}: </span>{code}
              </Link>
            );
          }
        })}
      </nav>

      <div className="header__container" ref={headerRef}>
        <div className="header__wrapper">
          <Link to={siteUrl} className="header__logo" aria-label={t("To start page")}>
            <HeaderLogo />
          </Link>
          <button
            type="button"
            onClick={() => onViewToggleClick("menu")}
            className="header_button header_button--menu"
            aria-controls="header-navigation"
            aria-expanded={currentView === "menu"}
            id="header-navigation-button"
          >
            {currentView === "menu" ? t("Close") : t("Menu")}
          </button>
          <button
            type="button"
            onClick={() => onViewToggleClick("search")}
            className="header_button header_button--search"
            aria-controls="header-search"
            aria-expanded={currentView === "search"}
            id="header-search-button"
          >
            {currentView === "search" ? t("Close") : t("Search")}
          </button>
        </div>

        <div
          className="header__navigation"
          id="header-navigation"
          aria-hidden={currentView !== "menu"}
          aria-label={t("Navigation")}
        >
          <div className="header__navigation-wrapper">
            <PrimaryNavigation aria-label={t("Main navigation")} />
            <SecondaryNavigation
              {...helpLinksNavigationItems}
              label={t("Help links")}
            />
            <SecondaryNavigation
              {...socialNavigationItems}
              label={t("Social media links")}
            />
          </div>
        </div>
        <StateSearchParamsProvider>
          <SearchHandler>
            <HeaderSearchView isOpen={currentView === "search"} />
          </SearchHandler>
        </StateSearchParamsProvider>
      </div>
    </header>
  );
}
