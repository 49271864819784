import React from "react";

import { useHTMLProcessor } from "../../hooks/html-processor";
import transformWPPPageReferencesToGatsby from '../../../../utils/transformWPPageReferencesToGatsby';

export function WPCoreParagraphBlock({
  block: { originalContent, attributes: { content } },
  contentMedia
}) {
  if(content === "") {
    return null;
  }

  const { processContent } = useHTMLProcessor();

  const fromUrl = process.env.GATSBY_WORDPRESS_URL.replace("/wp", "");
  const newContent = transformWPPPageReferencesToGatsby(
    originalContent || "",
    fromUrl,
    process.env.GATSBY_SITE_URL,
  );

  const output = processContent(newContent, contentMedia);
  return <>{output}</>;
}
