import { Link } from "gatsby";
import React from "react";

export function SearchHit({ title, url }) {
  return (
    <li>
      <Link to={url}>{title}</Link>
    </li>
  );
}
