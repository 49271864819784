import { H } from "@jfrk/react-heading-levels";
import { usePageContext } from "../../hooks/page-context";
import cx from "classnames";
import React from "react";

import PubDate from "../../../../components/PubDate/PubDate";

import "./WPAcfIntroBlock.scss";

export function WPAcfIntroBlock({ block }) {
  const { text, heroHasQuirkyText } = block.intro;
  if (text) {
    const [pageContext] = usePageContext();
    const { contentNode: { dateGmt: date, displaySettings } = {} } =
      pageContext;

    return (
      <div className="intro wrapper wrapper--flex wrapper--wide">
        <div className="wrapper__inner">
          <H
            className={cx(
              "display-intro",
              "text-align-center",
              heroHasQuirkyText && `font-feauture-quirky`,
            )}
          >
            {text}
          </H>
          {displaySettings && displaySettings.displayPublishedDate && (
            <PubDate date={date} />
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
