import React from "react";
import cx from "classnames";
import { WPBlocks } from "../WPBlocks";
import "./WPCoreColumnBlock.scss";

const WPCoreColumnBlock = (props) => {
    const { block: {
        innerBlocks,
        attributes: { width },
        size,
        isStackedOnMobile,
    } } = props;

    const blocks = [...innerBlocks].map((block, index) => {
        return {
          ...block,
          ...innerBlocks[index],
        };
    });

    const style = {
        ...(width && {width: width}),
    };

    const classes = cx(
        "column",
        { "column--stacked-mobile": isStackedOnMobile },
        { "column--no-stacked": !isStackedOnMobile },
        { [`column--${size}`]: size && !width},
    );

    return (
        <li className={classes} style={style}>
            <WPBlocks
                blocks={blocks}
            />
        </li>
    );
};

WPCoreColumnBlock.defaultProps = {
    block: {
        innerBlocks: [],
        attributes: {
            width: null,
        },
        size: 'full',
    },
    isStackedOnMobile: true,
};

export default WPCoreColumnBlock;