import cx from "classnames";
import React from "react";

import Timeline from "../../../../components/Timeline";

import "./WPAcfTimelineBlock.scss";

export function WPAcfTimelineBlock({ block }) {
  const {
    timeline: { items },
  } = block;

  return (
    <div className="wrapper wrapper--flex timeline-block wrapper--no-padding wrapper--full mb-xxl">
      <Timeline items={items} />
    </div>
  );
}
