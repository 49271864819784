import React from "react";
import cx from "classnames";
import { Section } from "@jfrk/react-heading-levels";
import { WPBlocks } from "../WPBlocks";
import "./WPCoreCoverBlock.scss";

const WPCoreCoverBlock = (props) => {
    const { block: { innerBlocks, attributes }, isLastBlock, hasFooterCards } = props;
    const {
        align,
        backgroundColor,
        textColor,
        style,
        backgroundType,
        isRepeated,
        contentPosition,
        url,
        overlayColor,
        customOverlayColor,
        dimRatio,
        minHeight,
        focalPoint,
        hasParallax,
    } = attributes;

    // Set is empty if only one empty paragraph exists
    let hasEmptyContent = false;
    const blocks = [...innerBlocks].map((block, index) => {
        const {name, attributes: {content}} = block;
        hasEmptyContent = name === 'core/paragraph' && content === '';

        return {
          ...block,
          ...innerBlocks[index],
        };
    });

    const focalPos = focalPoint ? `${focalPoint.x * 100}% ${focalPoint.y * 100}%` : null;
    const backgroundPosition = focalPos ? focalPos : contentPosition;

    const isEmpty = (hasEmptyContent && blocks.length === 1) || blocks.length <= 0;

    // If cover has a specific height, make sure padding is added instead of height to keep aspect ratio
    // Calculate aspect ratio from desktop 1440px width
    const aspectRatio = url && minHeight && isEmpty ? minHeight / 1440 : null;

    const hexTextColor = style?.color?.text;
    const hexBgColor = style?.color?.background;
    const styleAttr = {
        ...(hexTextColor && {color: hexTextColor}),
        ...(hexBgColor && {backgroundColor: hexBgColor}),
        ...(url && {
            backgroundImage: `url(${url})`,
            backgroundRepeat: isRepeated ? 'repeat' : 'no-repeat',
            backgroundPosition: backgroundPosition ? backgroundPosition : 'center center',
            backgroundSize: isRepeated ? null : 'cover',
            backgroundAttachment: hasParallax ? 'fixed' : 'scroll',
        }),
        ...(minHeight && !aspectRatio && {minHeight: `${minHeight}px`}),
        ...(aspectRatio && {paddingBottom: `${Math.round(aspectRatio * 100)}%`}),
    };

    const overlayStyle = {
        ...(customOverlayColor && {backgroundColor: customOverlayColor}),
        opacity: dimRatio / 100,
    };

    const classes = cx(
        "cover-block",
        { "cover-block--has-bg": hexBgColor || backgroundColor || url },
        { "cover-block--has-color": hexTextColor || textColor },
        { "cover-block--has-name-color": textColor },
        { [`cover-block--color-${textColor}`]: textColor},
        { "cover-block--has-name-bg": backgroundColor },
        { [`cover-block--bg-${backgroundColor}`]: backgroundColor},
        { "cover-block--has-name-overlay": overlayColor },
        { [`cover-block--overlay-${overlayColor}`]: overlayColor},
        { "cover-block--last-block": isLastBlock && hasFooterCards },
        { "cover-block--aspect-ratio": aspectRatio},
        {'wrapper--align-default': !align},
        {[`wrapper--align-${align}`]: align},
    );

    return (
        <section className={classes} style={styleAttr}>
            {(overlayColor || customOverlayColor) &&
                <div className="cover-block__overlay" style={overlayStyle} />
            }

            <div className="cover-block__blocks">
                <WPBlocks
                    blocks={blocks}
                    skipSection={true}
                />
            </div>
        </section>
    );
};

WPCoreCoverBlock.defaultProps = {
    block: {
        innerBlocks: [],
        attributes: {
            align: null,
            backgroundColor: null,
            textColor: null,
            style: {
                color: {
                    backgroundColor: null,
                    text: null,
                },
            },
        },
    },
    isLastBlock: false,
    hasFooterCards: false,
};

export default WPCoreCoverBlock;