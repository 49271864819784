import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import { usePageContext } from "../../@whitespace/gatsby-theme-wordpress/hooks/page-context";
import cx from "classnames";
import React, { useState } from "react";
import extractPathFromUrl from "../../utils/extractPathFromUrl";

import Icon from "../Icon/Icon";

import "./PrimaryNavigationList.scss";

const Arrow = () => {
  return (
    <>
      <span
        className="primary-navigation-list__line"
        style={{
          WebkitMaskImage: "url(/icons/arrow-line-down.svg)",
          maskImage: "url(/icons/arrow-line-down.svg)",
        }}
      />
      <span className="primary-navigation-list__arrow">
        <Icon name="arrow-down" />
      </span>
    </>
  );
};

export default function PrimaryNavigationList({ items }) {
  const [pageContext] = usePageContext();
  const { contentNode: currentPage } = pageContext;
  const [expandedItem, setExpandedItem] = useState(null);

  return (
    <ul className="primary-navigation-list">
      {items.map((item, index) => {
        return (
          <li
            key={index}
            className={cx(
              "primary-navigation-list__item",
              expandedItem === index && "is-open",
            )}
          >
            <Link
              to={extractPathFromUrl(item.url)?.replace(/#$/, "")}
              fallbackElement="button"
              tabIndex="0"
              onClick={(e) =>
                e.target.hasAttribute("href") ||
                setExpandedItem(expandedItem === index ? null : index)
              }
              className={cx(
                "primary-navigation-list__link",
                currentPage && currentPage.id == item.id && "current",
                item.displayQuirkyInMenu && "font-feauture-quirky",
              )}
            >
              {item.label}
              {item.children.length > 0 && !item.menuParentId && <Arrow />}
            </Link>
            {item.children.length > 0 && (
              <PrimaryNavigationList items={item.children} />
            )}
          </li>
        );
      })}
    </ul>
  );
}
