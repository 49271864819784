import React from "react";

import { LightboxContainer } from "../Lightbox/LightboxContainer";

import { MasonryComponent } from "./MasonryComponent";

export function MasonryContainer({ ...restProps }) {
  return (
    <LightboxContainer>
      <MasonryComponent {...restProps} />
    </LightboxContainer>
  );
}
