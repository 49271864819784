import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import { usePageContext } from "../../@whitespace/gatsby-theme-wordpress/hooks/page-context";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import { getSiteMetaData } from "../../hooks/siteMeta";
import getSocialMediaItem from "../../utils/getSocialMediaItems";
import Icon from "../Icon/Icon";

import "./SharePage.scss";

export default function SharePage() {
  const { t } = useTranslation();
  const channels = ["twitter", "facebook"];
  const { siteUrl } = getSiteMetaData();
  const [pageContext] = usePageContext();
  const { contentNode: { uri } = {} } = pageContext;

  return (
    <ul className="share-page-list meta">
      {channels.map((channel, index) => {
        let item = getSocialMediaItem(channel, siteUrl + uri);
        return (
          <li className="share-page-list__item" key={index}>
            <Link
              to={item.link}
              className="share-page-list__link"
              target="_blank"
            >
              <Icon name={item.icon} />
              {t("Share on")} {item.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
