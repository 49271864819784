import { useHTMLProcessor } from "../../@whitespace/gatsby-theme-wordpress/hooks/html-processor";
import decode from "parse-entities";
import parseEntities from "parse-entities";
import React from "react";
import cx from "classnames";
import Button from "./../Button/Button";

import "./Table.scss";

export default function Table({ title, head, body, foot, caption, hasFixedLayout }) {
  const { processContent } = useHTMLProcessor();

  const classes = cx(
    "table",
    {"table--layout-default": !hasFixedLayout},
    {"table--layout-fixed": hasFixedLayout},
  );
  return (
    <div className={classes}>
      <table className="table__table">
        {title &&
          <caption className="table__title">{parseEntities(title)}</caption>
        }

        {head && (
          <thead className="table__head">
            <tr className="table__row">
              {head?.[0]?.cells?.map((cell, cellIndex) => {
                return <th className="table__cell table__cell--heading" key={cellIndex}>{cell.content}</th>;
              })}
            </tr>
          </thead>
        )}

        {body && (
          <tbody className="table__body">
            {body.map((row, rowIndex) => {
              return (
                <tr className="table__row" key={rowIndex}>
                  {row.cells.map((cell, cellIndex) => {
                    const processedHTML = processContent(cell.content);
                    let {
                      props: { children },
                    } = processedHTML;
                    let linkHref = children?.[0]?.props?.href;
                    let linkLabel = children?.[0]?.props?.children[0];
                    const newTab = children?.[0]?.props?.target ? true : false;
                    return linkHref ? (
                      <td className="table__cell" key={cellIndex}>
                        <Button
                          icon={false}
                          link={linkHref}
                          linkText={decode(linkLabel)}
                          newTab={newTab}
                        />
                      </td>
                    ) : (
                      <td
                        className="table__cell"
                        key={cellIndex}
                        dangerouslySetInnerHTML={{ __html: cell.content }}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}

        {foot && (
          <tfoot className="table__foot">
            <tr className="table__row">
              {foot?.[0]?.cells?.map((cell, cellIndex) => {
                return (
                  <td
                    className="table__cell"
                    key={cellIndex}
                    dangerouslySetInnerHTML={{ __html: cell.content }}
                  />
                );
              })}
            </tr>
          </tfoot>
        )}
      </table>

      {caption &&
        <span className="table__caption mt-s">{caption}</span>
      }
    </div>
  );
}
