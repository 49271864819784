import React from "react";

import MetadataList from "../../../../components/MetadataList/MetadataList";

export function WPAcfMetadataListBlock({ block }) {
  let {
    metadataList: { list },
  } = block;

  list = list || [];
  const items = list.map(({ item }) => item).filter(Boolean);
  if (!items || !items.length) {
    return null;
  }
  return <MetadataList items={items} />;
}
