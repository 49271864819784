import { SearchQueryForm } from "@whitespace/gatsby-theme-search/src/components/SearchQueryForm";
import { SearchResults } from "@whitespace/gatsby-theme-search/src/components/SearchResults";
import { useSiteSearch } from "@whitespace/gatsby-theme-search/src/hooks/search";
import { useMenu } from "../../@whitespace/gatsby-theme-wordpress/hooks/menus";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import SecondaryNavigation from "./../SecondaryNavigation/SecondaryNavigation";

export function HeaderSearchView({isOpen}) {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const quickLinksNavigationItems = useMenu(
    currentLanguage === "sv"
      ? "quick_links"
      : `quick_links___${currentLanguage}`,
  );
  const { query } = useSiteSearch();

  return (
    <div
      className="header__search"
      id="header-search"
      aria-hidden={!isOpen}
      aria-label={t("Search")}
    >
      <SearchQueryForm />
      {query.length > 0 && <SearchResults className="search-results" />}
      <SecondaryNavigation
        {...quickLinksNavigationItems}
        label={t("Quick links")}
        showTitle={true}
      />
    </div>
  );
}
