import React from "react";
import cx from "classnames";
import { WPBlocks } from "../WPBlocks";
import "./WPCoreButtonsBlock.scss";

const WPCoreButtonsBlock = (props) => {
    const { block: { innerBlocks, attributes } } = props;
    const { align } = attributes;

    const classes = cx(
        "buttons-block",
        "wrapper",
        {'wrapper--align-default': !align},
        {[`wrapper--align-${align}`]: align},
    );

    return (
        <div className={classes}>
            <div className="buttons-block__wrapper">
                <WPBlocks
                    blocks={innerBlocks}
                />
            </div>
        </div>
    );
};

export default WPCoreButtonsBlock;
