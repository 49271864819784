import React from "react";

import PushSquareNumbers from "../../../../components/PushSquare/PushSquareNumbers";

import "./WPAcfDynamicNumbersBlock.scss";

function useDynamicNumbers(numbers) {
  return numbers.map((number) => {
    number.type = number.type
      .replace("AcfDynamicNumbersBlock_Dynamicnumbers_cards_Numbers_", "")
      .toLowerCase();

    return number;
  });
}

export function WPAcfDynamicNumbersBlock({ block, contentMedia }) {
  const { dynamicNumbers } = block;

  let itemsArr = [];
  dynamicNumbers?.cards?.map(
    ({ description, numbers, ...cardProps }, index) => {
      itemsArr.push({
        ...cardProps,
        body: description,
        numbers: useDynamicNumbers(numbers),
      });
    },
  );
  return (
    <div className="wrapper wrapper--flex wrapper--max wrapper--scroll mb-m">
      <PushSquareNumbers
        items={itemsArr}
        alignment={dynamicNumbers.alignment}
      />
    </div>
  );
}
