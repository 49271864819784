import cx from "classnames";
import React from "react";

import transformWPPPageReferencesToGatsby from '../../../../utils/transformWPPageReferencesToGatsby';

import "./WPCoreHeadingBlock.scss";

export function WPCoreHeadingBlock({ block }) {
  const {
    level,
    align,
    content,
    anchor,
    className,
    quirky,
    textAlign,
    textColor,
    style,
  } = block.attributes;

  const idAttr = anchor && {
    id: anchor,
  };

  const fromUrl = process.env.GATSBY_WORDPRESS_URL.replace("/wp", "");

  const updatedContent = transformWPPPageReferencesToGatsby(
    content || "",
    fromUrl,
    process.env.GATSBY_SITE_URL,
  ).replaceAll('&amp;shy;', '&shy;');

  const hexTextColor = style?.color?.text;
  const hexBgColor = style?.color?.background;
  const styleAttr = {
    ...(hexTextColor && {color: hexTextColor}),
  };

  const Heading = `h${level}`;

  const alignment = align ? align : 'default';

  const displayClass = className && className.includes('display-') ? null : `display-${level}`;

  return (
    <Heading
      className={cx(
        'heading',
        alignment && `wrapper wrapper--align-${alignment}`,
        textAlign && `text-align-${textAlign}`,
        quirky && `font-feauture-quirky`,
        { "heading--has-color": hexTextColor || textColor },
        { "heading--has-name-color": textColor },
        { [`heading--color-${textColor}`]: textColor},
        displayClass,
        className,
      )}
      style={styleAttr}
      {...idAttr}
      dangerouslySetInnerHTML={{ __html: updatedContent }}
    />
  );
}
