import cx from "classnames";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import extractPathFromUrl from "../../utils/extractPathFromUrl";
import Button from "./../Button/Button";
import Card from "./../Card/Card";

import "./Push.scss";

export default function Push({
  title,
  quirkyTitle,
  content,
  items,
  cta,
  className,
}) {
  const {t} = useTranslation();
  return (
    <div className={cx("push", className)}>
      <div className="push__media">
        <div className="push-list push-list--horizontal">
          {items?.map((card, cardIndex) => {
            return (
              <Card
                key={cardIndex}
                className="card--portrait"
                gradient="card--gradient"
                title={card.title}
                quirkyTitle={card.quirkyTitle}
                titleSize="display-2"
                media={card.media}
                description={card.description}
                link={card.link}
              />
            );
          })}
        </div>
      </div>
      <div className="push__content mt-m">
        {title && (
          <h2
            className={cx(
              "push__headline",
              quirkyTitle && "font-feauture-quirky",
              "display-2",
              "mb-m",
            )}
          >
            {title}
          </h2>
        )}
        {content && (
          <div className="" dangerouslySetInnerHTML={{ __html: content }} />
        )}
        {!!cta?.buttons?.some((item) => item.button) && (
          <ul className="push__button-group pt-m plain-list">
            {cta?.buttons
              ?.filter((item) => item.button)
              .map(({ button }, buttonIndex) => {
                // Check if the link text isn't descriptive enough
                const buttonTitle = button.title;
                const fixAccessibility = buttonTitle === "Läs mer" || buttonTitle === "Read more";
                return (
                  <li className="plain-list__item" key={buttonIndex}>
                    <Button
                      link={extractPathFromUrl(button.url)}
                    >
                      {buttonTitle}
                      {fixAccessibility &&
                        <span className="visually-hidden"> {t("about \"{}\"", {title: title})}</span>
                      }
                    </Button>
                  </li>
                );
              })}
          </ul>
        )}
      </div>
    </div>
  );
}
