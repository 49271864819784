import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import { usePageContext } from "../../@whitespace/gatsby-theme-wordpress/hooks/page-context";
import cx from "classnames";
import React from "react";
import extractPathFromUrl from "../../utils/extractPathFromUrl";

import "./SecondaryNavigation.scss";

export default function SecondaryNavigation({
  items = [],
  label,
  showTitle = false,
  className,
}) {
  const [pageContext] = usePageContext();
  const { contentNode: currentPage } = pageContext;

  return (
    <nav
      className={cx("secondary-navigation", className)}
      aria-label={label}
    >
      {showTitle && <span className="secondary-navigation__headline">{label}</span>}
      <ul className="secondary-navigation__list">
        {items.map((item, index) => {
          return (
            <li key={index} className="secondary-navigation__list-item">
              <Link
                to={extractPathFromUrl(item.url)}
                className={cx(
                  "secondary-navigation__list-link",
                  currentPage && currentPage.id == item.id && "current",
                )}
              >
                {item.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
