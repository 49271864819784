import React, { createContext, useState } from "react";

export function LightboxContainer({ children, ...restProps }) {
  const [lightboxContext, setLightboxContext] = useState({
    mediaIndex: 0,
    isOpen: false,
    items: [],
  });

  return (
    <LightboxContext.Provider value={[lightboxContext, setLightboxContext]}>
      <div {...restProps}>{children}</div>
    </LightboxContext.Provider>
  );
}

export const LightboxContext = createContext();
