import cx from "classnames";
import { Link } from "gatsby";
import React from "react";
import extractPathFromUrl from "../../utils/extractPathFromUrl";
import Button from "./../Button/Button";
import { MediaElement } from "./../MediaElement";

import "./Card.scss";

const ConditionallyWrapWithLink = ({ condition, wrapper, children }) => {
  if (condition) {
    return wrapper(children);
  }
  return children;
};

export default function Card({
  className,
  media,
  gradient,
  title,
  description,
  link,
  titleSize,
  quirkyTitle,
  clickableCard,
}) {
  const isClickable = clickableCard === "true";

  // If clickable, no button is needed
  const hideButton = isClickable;

  const classes = cx(
    'card',
    {'card--clickable': isClickable},
    className,
    gradient,
  );
  return (
    <div className={classes}>
      {link &&
        <ConditionallyWrapWithLink
          condition={link}
          wrapper={(children) =>
            link.target ? (
              <a
                className="card__link"
                href={extractPathFromUrl(link.url)}
                target="_blank"
                rel="noreferrer"
              >
                {children}
              </a>
            ) : (
              <Link className="card__link" to={extractPathFromUrl(link.url)}>
                {children}
              </Link>
            )
          }
        >
          <span className="visually-hidden">{title || link.title}</span>
        </ConditionallyWrapWithLink>
      }

      <div className="card__wrapper">

        {media && (
          <div className="card__media">
            <MediaElement
              video={
                media.mimeType.includes("video")
                  ? {
                      ...media,
                      loop: true,
                      muted: true,
                    }
                  : {}
              }
              image={media.mimeType.includes("image") ? media : {}}
              className="card__media-element"
            />
          </div>
        )}
        <div className="card__content">
          <div className="card__content-inner">
            {title && (
              <h2
                className={cx(
                  "card__title mb-s",
                  quirkyTitle && "font-feauture-quirky",
                  titleSize,
                )}
              >
                {title}
              </h2>
            )}
            {description && (
              <p className="card__description mb-s">{description}</p>
            )}
            {!hideButton && link && link.title && (
              <Button
                className="card__button card__button--link"
                tabIndex="-1"
                type="button"
              >{link.title}<span /></Button>
            )}
            {!hideButton && link && !link.title && (
              <span
                className="card__arrow"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
