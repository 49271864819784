import React from "react";
import { Hero } from "./Hero";

export function HeroContainer({
  title,
  heroTitle,
  heroSubtitle,
  heroHasQuirkyText,
  heroHasOverlay,
  heroIsInverted,
  heroMedia,
}) {
  return (
    <Hero
      title={heroTitle || title}
      subtitle={heroSubtitle}
      hasOverlay={heroHasOverlay}
      hasQuirkyText={heroHasQuirkyText}
      media={heroMedia}
      isInverted={heroIsInverted}
    />
  );
}
