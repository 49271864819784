import { useMenu } from "../../@whitespace/gatsby-theme-wordpress/hooks/menus";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "./PrimaryNavigation.scss";

import flatListToHierarchical from "../../utils/flatListToHierarchical";
import PrimaryNavigationList from "../PrimaryNavigationList/PrimaryNavigationList";

export default function PrimaryNavigation({ ...restProps }) {
  const {
    i18n: { language: currentLanguage },
  } = useTranslation();

  const menu = useMenu(
    currentLanguage === "sv"
      ? `primary_navigation`
      : `primary_navigation___${currentLanguage}`,
  );

  if (!menu) return null;

  let { items = [], label, showTitle = false } = menu;
  if (items.length) {
    items = flatListToHierarchical(items);
  }

  if (!items.length) return null;

  return (
    <nav
      className="primary-navigation"
      aria-label={!showTitle ? label : ""}
      {...restProps}
    >
      {showTitle && <h2 className="primary-navigation__headline">{label}</h2>}
      <PrimaryNavigationList items={items} />
    </nav>
  );
}
