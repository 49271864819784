import cx from "classnames";
import React, { useContext, useState, useEffect } from "react";
import Masonry from "react-masonry-css";

import { useSuccessiveColums } from "../../hooks/masonry";
import { LightboxContext } from "../Lightbox/LightboxContainer";
import Lightbox from "../Lightbox/Lightbox";

import "./Masonry.scss";

export function MasonryComponent({ items, columns, ...restProps }) {
  const successiveColums = useSuccessiveColums(columns, 1280, 320);

  const masonrySettings = {
    default: columns,
    ...successiveColums,
  };

  const [lightboxContext, setLightboxContext] = useContext(LightboxContext);

  useEffect(() => {
    setLightboxContext({
      ...lightboxContext,
      items: items,
    });
  }, []);

  return (
    <>
      <Masonry
        breakpointCols={masonrySettings}
        className={cx("masonry", columns === 1 && "masonry--single")}
        columnClassName="masonry__column"
        {...restProps}
      >
        {items.map((item, index) => {
          // let isVideo = item.url.includes(".mp4");

          return (
            <div className="masonry__holder" key={index}>
              {/* {isVideo ? (
                  <VideoElement
                    source={item.url}
                    className="masonry__video"
                    loop={true}
                    muted={true}
                    hidePlayIcon={true}
                    responsive={true}
                    width={"320"}
                    height={"240"}
                  />
                ) : ( */}
              <Image
                {...item}
                onClickHandler={() => {
                  setLightboxContext({
                    ...lightboxContext,
                    isOpen: true,
                    mediaIndex: index,
                  });
                }}
              />
            </div>
          );
        })}
      </Masonry>
      <Lightbox />
    </>
  );
}

const Image = ({
  src,
  alt,
  width,
  height,
  onClickHandler,
}) => {
  return (
    <img
      src={src}
      className="masonry__item"
      alt={alt}
      width={width}
      height={height}
      onClick={() => onClickHandler()}
    />
  );
};
