import { H } from "@jfrk/react-heading-levels";
import { usePageContext, PageContextProvider } from "../hooks/page-context";
import cx from "classnames";
import React, { useEffect } from "react";
import { graphql } from 'gatsby';

import SEO from "../../../components/seo";
import Blocks from "../../../components/Blocks";
import Footer from "../../../components/Footer/Footer";
import Header from "../../../components/Header/Header";
import { HeroContainer } from "../../../components/Hero/HeroContainer";
import PageBackgroundImage from "../../../components/PageBackgroundImage/PageBackgroundImage";

// This is the default template called from createPages
// This is where the pageContext is added and should therefore not change anything below this point
export default function SingleTemplate({ pageContext, children }) {
  const [currPageContext, setCurrPageContext] = usePageContext();
  useEffect(() => {
    // Only set new context if actually changed
    if(pageContext !== currPageContext) {
      setCurrPageContext(pageContext);
    }
  }, [pageContext]);

  let contentNode = pageContext?.contentNode;

  if (!contentNode) {
    return null;
  }

  const lang = pageContext?.language;

  const {
    hero,
    title,
    titleHasQuirkyText,
    isFrontPage,
    contentMedia,
    blocksJSON,
    blocks,
    pageAppearance: { background, customBackground, foreground } = {},
    translations,
  } = contentNode;

  const pageBlocks = {
    contentMedia,
    blocksJSON,
    blocks,
  };

  const linkColor = foreground === "light" || foreground === "white" ? "light" : "theme";
  const linkColorInverse = foreground === "light" || foreground === "white" ? "theme" : "light";

  const themeColor = {
    colorScheme:
      background !== "custom"
        ? `color-scheme--${background}`
        : foreground
        ? `color-scheme--foreground-${foreground}`
        : "",
    customScheme:
      background === "custom"
        ? {
            "--color-foreground": `var(--color-${foreground})`,
            "--color-foreground-inverse": `var(--color-${
              foreground === "light" ? "dark" : "light"
            })`,
            "--color-link-foreground": `var(--color-${linkColor})`,
            "--color-link-foreground-inverse": `var(--color-${linkColorInverse})`,
            "--icon-external-foreground": `var(--icon-external-${linkColor})`,
            "--icon-external-foreground-inverse": `var(--icon-external-${linkColorInverse})`,
            "--icon-download-foreground": `var(--icon-download-${linkColor})`,
            "--icon-download-foreground-inverse": `var(--icon-download-${linkColorInverse})`,
            "--color-background": `#${customBackground}`,
            "--color-background-inverse": `var(--color-${foreground})`,
            "--header-foreground":
              hero && hero.heroActive
                ? "var(--color-light)"
                : "var(--color-foreground)",
          }
        : {
            "--header-foreground":
              hero && hero.heroActive
                ? "var(--color-light)"
                : "var(--color-foreground)",
          },
  };

  return (
    <PageContextProvider value={pageContext}>
      <SEO
        title={title}
        lang={lang}
        translations={translations}
        isFrontPage={isFrontPage}
      />
      <div
        className={cx("template", "box", themeColor.colorScheme)}
        style={themeColor.customScheme}
      >
        <Header translations={translations} />
        <div className="page-wrapper">
          <PageBackgroundImage />
          <main id="main">
            {hero && hero.heroActive ? (
              <HeroContainer title={title} {...hero} />
            ) : (
              <H
                className={cx(
                  "display-1 text-align-center mt-xxl",
                  "page-title",
                  {"font-feauture-quirky": titleHasQuirkyText}
                )}
              >
                {(hero && hero.heroTitle) || title}
              </H>
            )}
            {(!!pageBlocks.blocksJSON || !!pageBlocks.blocks) && <Blocks {...pageBlocks} />}

            {children}
          </main>
          <Footer />
        </div>
      </div>
    </PageContextProvider>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
