import cx from "classnames";
import React from "react";
import ReactPlayer from "react-player/lazy";

import Icon from "./../Icon/Icon";

import "./MediaElement.scss";

export function VideoElement({
  source,
  poster,
  loop = false,
  muted = false,
  hidePlayIcon = false,
  responsive = true,
  playsinline = false,
  width,
  height,
  className,
  autoPlay = true,
  controls = false,
}) {
  const playing = autoPlay || (!loop && poster);
  return (
    <div
      className={cx(
        "player-wrapper",
        responsive && "player-wrapper--responsive",
        className,
      )}
    >
      <ReactPlayer
        className="react-player"
        url={source}
        light={!loop && poster}
        width={width ? width : "100%"}
        height={height ? height : "100%"}
        controls={!loop || controls}
        loop={loop}
        muted={muted}
        playsinline={playsinline}
        playIcon={
          !hidePlayIcon && (
            <span className="react-player__icon-wrapper">
              <Icon name="polygon-1" size="775%" />
            </span>
          )
        }
        playing={playing}
      />
    </div>
  );
}
