import cx from "classnames";
import React from "react";

import Button from "../../../../components/Button/Button";

export function WPAcfButtonBlock({ block }) {
  const {
    button: { link, buttonColor, buttonColorCustom, buttonTextColor },
    attributes: { align },
  } = block;

  if (!link) return null;

  const { url, title, target } = link;

  return (
    <div className="wrapper wrapper--flex wrapper--max mb-m">
      <div className={cx("cta", "align-" + (align || "center"))}>
        <Button
          style={
            buttonColor === "custom"
              ? {
                  "--background": `${buttonColorCustom}`,
                  "--border": `${buttonColorCustom}`,
                  "--foreground": `var(--color-${buttonTextColor})`,
                }
              : null
          }
          link={url}
          linkText={title}
          target={target}
        />
      </div>
    </div>
  );
}
