import React from "react";
import cx from "classnames";
import { WPBlocks } from "../WPBlocks";
import "./WPCoreGroupBlock.scss";

const WPCoreGroupBlock = (props) => {
    const { block: { innerBlocks, attributes }, isLastBlock, hasFooterCards } = props;
    const { align, backgroundColor, textColor, style, layout } = attributes;

    const layoutType = layout?.type;

    const blocks = [...innerBlocks].map((block, index) => {
        return {
          ...block,
          ...innerBlocks[index],
        };
    });

    const hexTextColor = style?.color?.text;
    const hexBgColor = style?.color?.background;
    const styleAttr = {
        ...(hexTextColor && {color: hexTextColor}),
        ...(hexBgColor && {backgroundColor: hexBgColor}),
    };

    const isConstrained = layoutType === 'constrained' || layout?.inherit === true;

    const classes = cx(
        "group-block",
        { "group-block--has-bg": hexBgColor || backgroundColor },
        { "group-block--has-color": hexTextColor || textColor },
        { "group-block--has-name-color": textColor },
        { [`group-block--color-${textColor}`]: textColor},
        { "group-block--has-name-bg": backgroundColor },
        { [`group-block--bg-${backgroundColor}`]: backgroundColor},
        { "group-block--last-block": isLastBlock && hasFooterCards },
        { "group-block--is-layout-constrained": isConstrained },
        { "group-block--is-layout-flow": !isConstrained },
        { "group-block--align-default": !align },
        {[`group-block--align-${align}`]: align},
        "wrapper",
        {'wrapper--align-default': !align},
        {[`wrapper--align-${align}`]: align},
    );

    return (
        <section className={classes} style={styleAttr}>
            <div className="group-block__blocks">
                <WPBlocks
                    blocks={blocks}
                    skipSection={true}
                />
            </div>
        </section>
    );
};

WPCoreGroupBlock.defaultProps = {
    block: {
        innerBlocks: [],
        attributes: {
            align: null,
            backgroundColor: null,
            textColor: null,
            style: {
                color: {
                    backgroundColor: null,
                    text: null,
                },
            },
        },
    },
    isLastBlock: false,
    hasFooterCards: false,
};

export default WPCoreGroupBlock;