import parse from "html-react-parser";
import React, { useContext } from "react";
import Lightbox from "react-image-lightbox";

import { LightboxContext } from "../Lightbox/LightboxContainer";

import "./Lightbox.scss";
export default function LightBox({ ...restProps }) {
  const [lightboxContext, setLightboxContext] = useContext(LightboxContext);

  const { mediaIndex, isOpen, items } = lightboxContext || {};

  const imageCaption = items[mediaIndex]?.description
    ? parse(items[mediaIndex].description)
    : null;

  return (
    isOpen && (
      <Lightbox
        mainSrc={items[mediaIndex].src}
        nextSrc={items[(mediaIndex + 1) % items.length].src}
        prevSrc={items[(mediaIndex + items.length - 1) % items.length].src}
        imageCaption={imageCaption}
        onCloseRequest={() =>
          setLightboxContext({
            ...lightboxContext,
            isOpen: false,
          })
        }
        wrapperClassName="masonry__lightbox"
        nextLabel={"Next item"}
        prevLabel={"Previous item"}
        enableZoom={false}
        closeLabel={"Close lightbox"}
        animationDisabled={true}
        imagePadding={50}
        onMovePrevRequest={() =>
          setLightboxContext({
            ...lightboxContext,
            mediaIndex: (mediaIndex + items.length - 1) % items.length,
          })
        }
        onMoveNextRequest={() =>
          setLightboxContext({
            ...lightboxContext,
            mediaIndex: (mediaIndex + 1) % items.length,
          })
        }
        {...restProps}
      />
    )
  );
}
