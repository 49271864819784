import React from "react";
import cx from 'classnames';
import Masonry from "../../../../components/Masonry";
import "./WPCoreGalleryBlock.scss";

export function WPCoreGalleryBlock({ block }) {
  if (!block) {
    return null;
  }

  const {
    innerBlocks,
    attributes: { images, columns = 3, align },
  } = block;

  if(!images && !innerBlocks) {
    return null;
  }

  const blocks = innerBlocks && innerBlocks.length > 0 ? innerBlocks : images;
  const items = blocks.map((item) => {
    const image = item?.attributes || item;
    const mediaDetails = item?.mediaItem?.mediaDetails;
    return {
      ...image,
      src: image.fullUrl ? image.fullUrl : image.url,
      width: mediaDetails?.width,
      height: mediaDetails?.height,
    };
  });

  const classes = cx(
    'gallery-block',
    'mt-xl',
    'mb-xl',
    'wrapper',
    {'wrapper--align-default': !align},
    {[`wrapper--align-${align}`]: align},
  );

  return (
    <div className={classes}>
      <Masonry items={items} columns={columns} />
    </div>
  );
}
