import React from "react";

import "./MetadataList.scss";

export default function MetadataList({ items }) {
  if (!items || !items.length) {
    return null;
  }

  return (
    <div className="metadata-list wrapper--max mb-xl">
      <ul className="meta">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
}
