import React from "react";

import PushSquare from "../../../../components/PushSquare/PushSquare";

import "./WPAcfPushSquareBlock.scss";

export function WPAcfPushSquareBlock({ block, contentMedia }) {
  const { pushSquare } = block;

  let itemsArr = [];
  pushSquare?.cards?.map((card, index) => {
    itemsArr.push({
      title: card.title,
      body: card.description,
      link: card.link,
      media: card.media,
    });
  });

  return (
    <div className="wrapper wrapper--flex wrapper--max wrapper--scroll mb-m">
      <PushSquare items={itemsArr} alignment={pushSquare.alignment} />
    </div>
  );
}
