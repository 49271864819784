import React from "react";
import cx from "classnames";
import { Section } from "@jfrk/react-heading-levels";
import { WPBlocks } from "../WPBlocks";
import "./WPCoreColumnsBlock.scss";

const WPCoreColumnsBlock = (props) => {
    const { block: { innerBlocks, attributes } } = props;
    const { align, textColor, style, isStackedOnMobile } = attributes;

    const countColomns = innerBlocks.length;
    const size = countColomns === 1 ? 'full' : (
        countColomns === 2 ? 'half' : (
            countColomns === 3 ? 'third' : 'fourth'
        )
    );
    const blocks = [...innerBlocks].map((block, index) => {
        return {
            ...block,
            ...innerBlocks[index],
            size: size,
            isStackedOnMobile: isStackedOnMobile,
        };
    });

    const hexTextColor = style?.color?.text;
    const styleAttr = {
        ...(hexTextColor && {color: hexTextColor}),
    };

    const classes = cx(
        "columns-block",
        { "columns-block--stacked-mobile": isStackedOnMobile },
        { "columns-block--no-stacked": !isStackedOnMobile },
        { "columns-block--has-color": hexTextColor || textColor },
        { "columns-block--has-name-color": textColor },
        { [`columns-block--color-${textColor}`]: textColor},
        "wrapper",
        {'wrapper--align-default': !align},
        {[`wrapper--align-${align}`]: align},
    );
    return (
        <div className={classes} style={styleAttr}>
            <ul className="columns-block__list">
                <WPBlocks
                    blocks={blocks}
                    skipSection={true}
                />
            </ul>
        </div>
    );
};

export default WPCoreColumnsBlock;