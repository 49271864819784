import React from "react";
import cx from "classnames";
import { MediaElement } from "../../../../components/MediaElement";
import "./WPCoreImageBlock.scss";

const WPCoreImageBlock = (props) => {
    const { block: {
        attributes: {
            width: attrWidth,
            height: attrHeight,
            url,
            caption,
            alt,
            align,
            sizeSlug,
        },
        mediaItem,
    } } = props;

    const mediaWidth = mediaItem?.mediaDetails?.width;
    const mediaHeight = mediaItem?.mediaDetails?.height;

    const width = attrWidth || mediaWidth;
    const height = attrHeight || mediaHeight;

    const style = {
        ...(attrWidth && {width: attrWidth}),
    };

    const image = {
        width: width,
        height: height,
        src: url,
        caption: caption,
        alt: alt,
    };

    const classes = cx(
        "image-block",
        { [`image-block--size-${sizeSlug}`]: sizeSlug && !attrWidth},
        'mt-m',
        'mb-m',
        'wrapper',
        {'wrapper--align-default': !align},
        {[`wrapper--align-${align}`]: align},
    );

    return (
        <div className={classes} style={style}>
            <MediaElement
                image={image}
            />
        </div>
    );
};

WPCoreImageBlock.defaultProps = {
    block: {
        attributes: {
            width: null,
        },
        sizeSlug: 'full',
    },
};

export default WPCoreImageBlock;