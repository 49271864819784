import { Icon } from "@whitespace/gatsby-theme-search/src/components/Icon";
import { useSiteSearch } from "@whitespace/gatsby-theme-search/src/hooks/search";
import cx from "classnames";
import { debounce } from "lodash";
import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import "@whitespace/gatsby-theme-search/src/components/SearchQueryForm/SearchQueryForm.scss";

export function SearchQueryForm({ ...restProps }) {
  let { t } = useTranslation();
  const { query, setQuery } = useSiteSearch();
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(query || "");
  }, [query]);

  const debouncedChangeHandler = useMemo(
    () => debounce((event) => setQuery(event.target.value), 200),
    [],
  );

  return (
    <form
      className="search-form"
      onSubmit={(event) => {
        event.preventDefault();
      }}
      {...restProps}
    >
      <label
        className={cx(
          "search-form__label",
          !value && "search-form__label--dimmed",
        )}
      >
        <Icon name="search" size="2.5rem" />
        <span className="visually-hidden">{t("Search this website")}</span>
        <input
          className="search-form__field"
          type="search"
          placeholder={t("What are you looking for?")}
          onChange={debouncedChangeHandler}
          onInput={(event) => {
            setValue(event.target.value);

            // Clear query when clicking on 'x' icon
            if (event.target.value === "") {
              setQuery("");
            }
          }}
          autoFocus={true}
        />
      </label>
    </form>
  );
}
