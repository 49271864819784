const isFile = (str) => {
  return /([^\/]+)(\.[^\.\/]+)$/.test(str);
};

export default function extractPathFromUrl(url) {
  if (url && process.env.GATSBY_WORDPRESS_URL && !isFile(url)) {
    const wpUrl = process.env.GATSBY_WORDPRESS_URL.replace("/wp", "");
    return url.replace(wpUrl, process.env.GATSBY_SITE_URL);
  }
  return url;
}
