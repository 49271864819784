import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function useFooterSettings() {
  const {
    i18n: { language = "sv" },
  } = useTranslation();

  const settings = useStaticQuery(graphql`
    query Footer {
      en: wp {
        footerSettings(language: EN) {
          footer {
            footerAreaCode
            footerCity
            footerEmailCourse
            footerEmailDefault
            footerEmailEducation
            footerPhone
            footerStreetAddress
          }
          footerCardList {
            showAsSlider
            cards {
              description
              link {
                target
                title
                url
              }
              quirkyTitle
              title
              media {
                mimeType
                mediaItemUrl
                ...WP_ImageMedium
              }
            }
          }
        }
      }
      sv: wp {
        footerSettings(language: SV) {
          footer {
            footerAreaCode
            footerCity
            footerEmailCourse
            footerEmailDefault
            footerEmailEducation
            footerPhone
            footerStreetAddress
          }
          footerCardList {
            showAsSlider
            cards {
              description
              link {
                target
                title
                url
              }
              quirkyTitle
              title
              media {
                mimeType
                mediaItemUrl
                ...WP_ImageMedium
              }
            }
          }
        }
      }
    }
  `);

  return settings[language]?.footerSettings;
}
