import cx from "classnames";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import extractPathFromUrl from "../../utils/extractPathFromUrl";
import NewsletterSignup from "../NewsletterSignup/NewsletterSignup";

import Button from "./../Button/Button";
import { MediaElement } from "./../MediaElement";

import "./TwoColumns.scss";

export default function TwoColumns({ title, content, cta, media, className }) {
  const {t} = useTranslation();
  return (
    <div className="wrapper wrapper--flex wrapper--max">
      <div className="wrapper__inner">
        <div className={cx("two-columns", "mb-lg", "mt-lg", className)}>
          <div className="two-columns__media">
            <MediaElement {...media} customHeight={370} />
          </div>
          <div className="two-columns__content">
            {title && (
              <h2 className="two-columns__headline display-2">{title}</h2>
            )}

            {content && (
              <div
                className="two-columns__text"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}

            {cta && (
              <div className="two-columns__cta mt-lg">
                {cta.complementaryText && (
                  <p className="two-columns__cta-text meta mb-s">
                    {cta.complementaryText}
                  </p>
                )}

                {cta.buttons && (
                  <ul className="two-columns__plain-list plain-list mt-s">
                    {cta.buttons
                      .filter((item) => item && item.button)
                      .map(({ button }, index) => {
                        // Check if the link text isn't descriptive enough
                        const buttonTitle = button.title;
                        const fixAccessibility = buttonTitle === "Läs mer" || buttonTitle === "Read more";
                        return (
                          <li
                            className="two-columns__plain-list-item plain-list__item"
                            key={index}
                          >
                            <Button link={extractPathFromUrl(button.url)}>
                              {buttonTitle}
                              {fixAccessibility &&
                                <span className="visually-hidden"> {t("about \"{}\"", {title: title})}</span>
                              }
                            </Button>
                          </li>
                        );
                      })}
                  </ul>
                )}
                {!!cta.newsletterSignupTrigger &&
                  !!cta.newsletterSignupTriggerLabel && (
                    <NewsletterSignup
                      label={cta.newsletterSignupTriggerLabel}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
