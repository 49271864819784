export function useTimelineLightboxItems(items) {
  let filteredItems = [];

  items.map(({ content }) => {
    content.map((contentItem) => {
      if (useTimelineItemsType(contentItem.type) === "media") {
        filteredItems.push(contentItem.image);
      }
    });
  });

  return filteredItems;
}

export function useTimelineItems(items) {
  let lightboxItemIndex = -1;

  const timelineItems = items.map((item) => {
    item.content.map((contentItem) => {
      contentItem.type = useTimelineItemsType(contentItem.type);

      if (contentItem.type === "media") {
        lightboxItemIndex++;
        contentItem.mediaIndex = lightboxItemIndex;
        delete contentItem?.image?.caption;
      }

      return contentItem;
    });

    return item;
  });

  return timelineItems;
}

export function useTimelineItemsType(type) {
  type = type.replace("acftimelineblock_timeline_items_content_", "");

  return type.toLowerCase();
}
