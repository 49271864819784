import cx from "classnames";
import parse from "html-react-parser";
import React from "react";

export default function Blockquote({ modifier, citation, content, children }) {
  return (
    <blockquote className={cx("blockquote", modifier)}>
      {children}
      {content && <>{parse(content)}</>}
      {citation && <cite className="mt-s">{parse(citation)}</cite>}
    </blockquote>
  );
}
