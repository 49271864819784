import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import cx from "classnames";
import parse from "html-react-parser";
import React from "react";
import extractPathFromUrl from "../../utils/extractPathFromUrl";

export default function TimelineHeader({ items, className, ...props }) {
  return (
    <div
      className={cx("timeline__wrapper timeline__wrapper--header", className)}
      {...props}
    >
      {items?.map(({ title, links }, index) => {
        links = links?.map(({ link }) => link).filter(Boolean);
        return (
          <div className="timeline__item" key={index}>
            <p className="timeline__date">{title}</p>
            {links?.length > 0 && (
              <div className="timeline__links">
                {links?.map(({ url, target, title }, index) => (
                  <Link to={extractPathFromUrl(url)} target={target} key={index}>
                    {parse(title)}
                  </Link>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
