import React from "react";

import SharePage from "../../../../components/SharePage/SharePage";

export function WPAcfSharePageBlock() {
  return (
    <div className="share-page wrapper wrapper--max mb-xxl">
      <SharePage />
    </div>
  );
}
