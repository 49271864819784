import React from "react";
import { useHTMLProcessor } from "../../hooks/html-processor";
import transformWPPPageReferencesToGatsby from '../../../../utils/transformWPPageReferencesToGatsby';

export function WPCoreListItemBlock({ block, contentMedia }) {
  const { processContent } = useHTMLProcessor();
  let originalContent = block.originalContent

  const fromUrl = process.env.GATSBY_WORDPRESS_URL.replace("/wp", "");
  originalContent = transformWPPPageReferencesToGatsby(
    originalContent || "",
    fromUrl,
    process.env.GATSBY_SITE_URL,
  )

  const content = processContent(originalContent, contentMedia);
  return <>{content}</>;
}
