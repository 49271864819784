import { Icon } from "@whitespace/components/Icon";
import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import cx from "classnames";
import decode from "parse-entities";
import React from "react";

import extractPathFromUrl from "../../utils/extractPathFromUrl";

import "@whitespace/components/src/Icon/Icon.scss";

import "./Button.scss";

export default function Button({
  modifier,
  link,
  linkText,
  icon,
  newTab = false,
  onClick,
  className,
  children: buttonContent,
  ...restProps
}) {

  const content = linkText ? decode(linkText) : buttonContent;

  const classes = cx(
    'button',
    {'button--cta': !link},
    modifier,
    className,
  );

  const Component = ({ children }) => {
    if (!link) {
      return (
        <button
          onClick={onClick}
          className={classes}
          {...restProps}
        >
          {children}
        </button>
      );
    }
    if (newTab) {
      return (
        <a
          href={extractPathFromUrl(link)}
          className={classes}
          target="_blank"
          rel="noreferrer"
          {...restProps}
        >
          {children}
          <span />
        </a>
      );
    }
    return (
      <Link
        to={extractPathFromUrl(link)}
        className={classes}
        {...restProps}
      >
        {children}
        <span />
      </Link>
    );
  };
  return (
    <Component>
      {content}
      {icon && <Icon name="arrow-right" />}
    </Component>
  );
}
