import cx from "classnames";
import React from "react";

import { MediaElement } from "../../../../components/MediaElement";

import "./WPAcfFeaturedMediaBlock.scss";

export function WPAcfFeaturedMediaBlock({ block }) {
  const { featuredMedia } = block;

  return (
    <div className="featured-media wrapper wrapper--flex wrapper--wide mb-m">
      <div className="wrapper__inner">
        <MediaElement {...featuredMedia} />
      </div>
    </div>
  );
}
