import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import extractPathFromUrl from "../utils/extractPathFromUrl";
import getOpenGraphContent from "../hooks/seo";
import { getSiteMetaData } from "../hooks/siteMeta";

function SEO({ description, lang, translations, meta, title = '', isFrontPage }) {
  const { title: siteTitle, author } = getSiteMetaData();

  const { metaTitle, metaDescription, metaImage, metaUrl } =
    getOpenGraphContent(getSiteMetaData(), {
      lang,
      title,
      description,
      meta,
    });

  const cookieScript = process.env.COOKIE_SCRIPT || '';
  const cookieScriptSrc = `//cdn.cookie-script.com/s/${cookieScript}.js`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={isFrontPage ? siteTitle : `%s - ${siteTitle}`}
      defaultTitle={siteTitle}
      link={[
        {
          rel: `icon`,
          type: `image/svg+xml`,
          href: `/favicon.svg`,
        },
        {
          rel: `alternate icon`,
          href: `/favicon.ico`,
        },
        {
          rel: `mask-icon`,
          href: `/safari-pinned-tab.svg`,
          color: `#004071`,
        },
      ]}
      meta={[
        {
          name: `referrer`,
          content: `no-referrer`,
        },
        {
          name: `apple-mobile-web-app-capable`,
          content: `yes`,
        },
        {
          name: `apple-mobile-web-app-status-bar-style`,
          content: `white`,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: `qzhm24gv51jbyi1i7en3l0q8wox353`,
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: "og:image",
                  content: metaImage.src,
                },
                {
                  property: "og:image:width",
                  content: metaImage.width,
                },
                {
                  property: "og:image:height",
                  content: metaImage.height,
                },
                {
                  name: "twitter:card",
                  content: "summary_large_image",
                },
              ]
            : [],
        )
        .concat(meta)}
    >
      {cookieScript &&
        <link rel="preload" as="script" href={cookieScriptSrc} />
      }
      {cookieScript &&
        <script
          type="text/javascript"
          charset="UTF-8"
          src={cookieScriptSrc}
        />
      }
      <link
        rel="alternate"
        href={metaUrl}
        hreflang={lang}
      />
      {translations.map((alternate, index) => (
        <link
          key={index}
          rel="alternate"
          href={extractPathFromUrl(alternate.link)}
          hreflang={alternate.language.code.toLowerCase()}
        />
      ))}
    </Helmet>
  );
}

SEO.defaultProps = {
  title: '',
  lang: `sv`,
  meta: [],
  description: ``,
  translations: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  translations: PropTypes.array,
};

export default SEO;
