import cx from "classnames";
import React from "react";

import Card from "../Card/Card";

import "./CardGroup.scss";

export default function CardGroup({ items, className }) {
  if (!items || !items.length) return null;

  return (
    <div className="wrapper wrapper--flex wrapper--max mb-xxl">
      <div className={cx("card-group", className)}>
        {items.length === 1 ? (
          <Card
            className="card--full-span card--full-span-tr"
            titleSize="display-2"
            {...items[0]}
          />
        ) : items.length === 2 ? (
          <div className="group-2">
            <Card
              className="card--landscape"
              titleSize="display-2"
              {...items[0]}
            />
            <Card
              className="card--landscape"
              titleSize="display-2"
              {...items[1]}
            />
          </div>
        ) : items.length === 3 ? (
          <>
            <Card
              className="card--full-span card--full-span-tr"
              titleSize="display-2"
              {...items[0]}
            />
            <div className="group-2">
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[1]}
              />
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[2]}
              />
            </div>
          </>
        ) : items.length === 4 ? (
          <>
            <Card
              className="card--full-span card--full-span-tr"
              titleSize="display-2"
              {...items[0]}
            />
            <div className="group-2">
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[1]}
              />
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[2]}
              />
            </div>
            <div className="card-group__row-2">
              <Card
                className="card--full-span card--full-span-tr"
                titleSize="display-2"
                {...items[3]}
              />
            </div>
          </>
        ) : items.length === 5 ? (
          <>
            <Card
              className="card--full-span card--full-span-tr"
              titleSize="display-2"
              {...items[0]}
            />
            <div className="group-2">
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[1]}
              />
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[2]}
              />
            </div>
            <div className="card-group__row-2">
              <Card
                className="card--full-span card--full-span-tr"
                titleSize="display-2"
                {...items[3]}
              />
              <div className="group-2">
                <Card
                  className="card--landscape"
                  titleSize="display-2"
                  {...items[4]}
                />
              </div>
            </div>
          </>
        ) : items.length === 6 ? (
          <>
            <Card
              className="card--full-span card--full-span-tr"
              titleSize="display-2"
              {...items[0]}
            />
            <div className="group-2">
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[1]}
              />
              <Card
                className="card--landscape"
                titleSize="display-2"
                {...items[2]}
              />
            </div>
            <div className="card-group__row-2">
              <Card
                className="card--full-span card--full-span-tr"
                titleSize="display-2"
                {...items[3]}
              />
              <div className="group-2">
                <Card
                  className="card--landscape"
                  titleSize="display-2"
                  {...items[4]}
                />
                <Card
                  className="card--landscape"
                  titleSize="display-2"
                  {...items[5]}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
