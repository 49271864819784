import React from "react";

import CardList from "../../../../components/CardList/CardList";

import "./WPAcfCardListBlock.scss";

export function WPAcfCardListBlock({ block, contentMedia }) {
  const { cardList } = block;

  let itemsArr = [];
  cardList?.cards?.map((card, index) => {
    itemsArr.push({
      title: card.title,
      quirkyTitle: card.quirkyTitle,
      description: card.description,
      link: card.link,
      media: card.media,
    });
  });

  const link = cardList?.link;
  const showAsSlider = cardList?.showAsSlider || false;

  return <CardList items={itemsArr} button={link} showAsSlider={showAsSlider} />;
}
