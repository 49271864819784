import { H } from "@jfrk/react-heading-levels";
import cx from "classnames";
import React from "react";

import Icon from "../Icon/Icon";
import { MediaElement } from "../MediaElement";

import "./Hero.scss";

export function Hero({
  title,
  subtitle,
  media,
  hasOverlay,
  hasQuirkyText,
  isInverted,
  className,
}) {
  media = media && {
    video: media.mimeType.includes("video") && {
      ...media,
      loop: true,
      muted: true,
      autoPlay: true,
      hidePlayIcon: true,
      responsive: false,
    },
    image: !media.mimeType.includes("video") ? media : null,
  };

  return (
    <div
      className={cx(
        "hero",
        "mb-lg",
        subtitle && "hero--fullscreen",
        hasOverlay && "hero--overlay",
        isInverted && "hero--inverted",
        className,
      )}
    >
      {media && (
        <div className="hero__media">
          <MediaElement {...media} className="hero__media-element" />
        </div>
      )}
      <div className="hero__wrapper">
        {title && (
          <H
            className={cx(
              "hero__title display-1",
              hasQuirkyText && "font-feauture-quirky",
            )}
            dangerouslySetInnerHTML={{__html: title}}
          />
        )}
        {subtitle && (
          <p className={cx("hero__subtitle display-preamble")}>{subtitle}</p>
        )}
        {subtitle && <Icon name="arrow-down" className="hero__icon" />}
      </div>
    </div>
  );
}
