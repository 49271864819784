import React from "react";

import TwoColumns from "../../../../components/TwoColumns/TwoColumns";

import "./WPAcfSplitMediaBlock.scss";

export function WPAcfSplitMediaBlock({ block }) {
  const { title, content, order, videoLink, cta, image, video } =
    block.splitMedia;

  const body = block.attributes?.data?.content; // Grab `content` from `attributes` to avoid a cache bug with Polylang

  const media = {
    videoLink,
    image,
    video,
  };

  return (
    <TwoColumns
      title={title}
      content={body || content}
      cta={cta}
      media={media}
      className={order == true ? "two-columns--inverse" : ""}
    />
  );
}
