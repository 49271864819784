import cx from "classnames";
import React, { Fragment } from "react";
import { WPBlocks } from "../WPBlocks";
import Blockquote from "../../../../components/Blockquote/Blockquote";

export function WPCoreQuoteBlock({ block: { innerBlocks, attributes }, contentMedia }) {
  const { align, citation, value } = attributes;

  const blocks = [...innerBlocks].map((block, index) => {
      return {
          ...block,
          ...innerBlocks[index],
      };
  });

  const textAlignments = ['left', 'right', 'center'];
  const alignment = align && !textAlignments.includes(align) ? align : 'default';

  return (
    <div
      className={cx(
        "quote-block",
        alignment && `wrapper wrapper--align-${alignment}`,
      )}
    >
      <Blockquote
        className={""}
        content={value}
        citation={citation}
        modifier={align && `align-${align} text-align-${align}`}
      >
        {(blocks.length > 0) &&
          <WPBlocks
            blocks={blocks}
            skipSection={true}
          />
        }
      </Blockquote>
    </div>
  );
}
