import { Formik, Form, Field } from "formik";
import addToMailchimp from "gatsby-plugin-mailchimp";
import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Modal from "react-modal";

import Button from "./../Button/Button";

import "./NewsletterSignup.scss";

export default function NewsletterSignup({ label }) {
  const { t } = useTranslation();
  const [responseMsg, setResponseMsg] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);

  const handleSubmit = async ({ email }) => {
    const response = await addToMailchimp(email);
    setResponseMsg(response.msg);
  };

  const closeModal = () => {
    setIsOpen(false);
    setResponseMsg("");
  };

  Modal.setAppElement("#___gatsby");

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <>
      <Button link={false} linkText={label} onClick={() => setIsOpen(true)} />
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        onRequestClose={closeModal}
      >
        <div className="newsletter-signup-popup">
          {responseMsg ? (
            <div className="newsletter-signup-confirmation">
              <div
                dangerouslySetInnerHTML={{
                  __html: responseMsg,
                }}
              />
              <Button linkText="Stäng" onClick={closeModal} />
            </div>
          ) : (
            <>
              <p>{t("newsletterCopy")}</p>
              <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
                <Form className="newsletter-signup-form">
                  <Field type="email" name="email" id="email" required />
                  <Button type="submit" linkText="Skicka" />
                </Form>
              </Formik>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
