import { useMenu } from "../../@whitespace/gatsby-theme-wordpress/hooks/menus";
import cx from "classnames";
import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import useFooterSettings from "../../hooks/footer";
import CardList from "../CardList/CardList";

import SecondaryNavigation from "./../SecondaryNavigation/SecondaryNavigation";

import "./Footer.scss";

export default function Footer({ secondaryNavigationItems }) {
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const {
    footer: {
      footerAreaCode,
      footerCity,
      footerEmailCourse,
      footerEmailDefault,
      footerEmailEducation,
      footerPhone,
      footerStreetAddress,
    },
    footerCardList: { showAsSlider = false, cards = [] },
  } = useFooterSettings();

  if (!secondaryNavigationItems) {
    secondaryNavigationItems = useMenu(
      currentLanguage === "sv"
        ? "quick_links"
        : `quick_links___${currentLanguage}`,
    )?.items;
  }

  const classes = cx(
    "footer",
    {"footer--cards": cards && cards.length > 0},
  );

  return (
    <footer className={classes}>
      {cards &&
        <CardList
          items={cards}
          modifier="footer"
          showAsSlider={showAsSlider}
        />
      }

      <div className="wrapper wrapper--flex wrapper--narrow">
        <div className="wrapper__inner">
          {!!secondaryNavigationItems?.length && (
            <SecondaryNavigation
              items={secondaryNavigationItems}
              label={t("Quick links")}
              showTitle={true}
            />
          )}
          <div className="footer__content">
            <h2 className="footer__headline">{t("Contact us")}</h2>
            <div className="footer__content-wysiwyg">
              {footerPhone && (
                <p>
                  Tel: <a href={`tel:${footerPhone}`}>{footerPhone}</a>
                </p>
              )}
              {footerEmailDefault && (
                <p>
                  {t("Email")}:{" "}
                  <a href={`mailto:${footerEmailDefault}`}>
                    {footerEmailDefault}
                  </a>
                </p>
              )}
              {footerEmailCourse && (
                <p>
                  {t("For questions regarding courses")}:{" "}
                  <a href={`mailto:${footerEmailCourse}`}>
                    {footerEmailCourse}
                  </a>
                </p>
              )}
              {footerEmailEducation && (
                <p>
                  {t("For questions regarding educational programs")}:{" "}
                  <a href={`mailto:${footerEmailEducation}`}>
                    {footerEmailEducation}
                  </a>
                </p>
              )}
              <br />
              <p>
                {t("Postal address")}: {footerStreetAddress}
                <br />
                {footerAreaCode} {footerCity}
              </p>
            </div>
          </div>
          <div className="footer__content">
            <p className="footer__headline">
              &copy; Cirkus Cirkör {new Date().getFullYear()}
            </p>
            <p>{t("cookieNotice")}</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
