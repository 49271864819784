import React from "react";
import { merge } from "lodash";
import useFooterSettings from "../../hooks/footer";
import { WPBlocks } from "../../@whitespace/gatsby-theme-wordpress/components/WPBlocks";

function prepareBlocks({ blocksJSON, blocks }) {
  return JSON.parse(blocksJSON).map((block, index) => {
    return merge(block, blocks[index]);
  });
}

export default function Blocks({ blocksJSON, blocks, contentMedia }) {
  const {
    footerCardList: { cards = [] },
  } = useFooterSettings();

  // TODO: Remove usage of BlocksJSON and instead only use blocks from graphql for improving createPages time
  const wpBlocks = !!blocksJSON ? prepareBlocks({ blocksJSON, blocks }) : blocks;

  return (
    <div className="article">
      <WPBlocks
        blocks={wpBlocks}
        contentMedia={contentMedia}
        hasFooterCards={cards.length > 0}
      />
    </div>
  );
}
