import React from "react";

import CardGroup from "../../../../components/CardGroup/CardGroup";

import "./WPAcfCardGroupBlock.scss";

export function WPAcfCardGroupBlock({ block }) {
  const { cardGroup } = block;
  let itemsArr = [];

  cardGroup?.cards?.map((card) => {
    itemsArr.push({
      title: card.title,
      link: card.link,
      media: card.media,
      clickableCard: card.clickableCard,
    });
  });

  return <CardGroup items={itemsArr} />;
}
