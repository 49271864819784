import React from "react";

import Push from "../../../../components/Push/Push";

import "./WPAcfDividedCardsBlock.scss";

export function WPAcfDividedCardsBlock({ block }) {
  const { dividedCards } = block;
  if(!dividedCards) {
    return null;
  }

  const { title, quirkyTitle, content, cards, order, cta } = dividedCards;

  const body = block.attributes?.data?.content; // Grab `content` from `attributes` to avoid a cache bug with Polylang

  return (
    <div className="wrapper wrapper--flex wrapper--max mb-xxl">
      <Push
        title={title}
        quirkyTitle={quirkyTitle}
        content={body || content}
        items={cards}
        cta={cta}
        className={order == true ? "push--inverse" : ""}
      />
    </div>
  );
}
