import useComponentSize from "../../@whitespace/gatsby-theme-wordpress/hooks/component-size";
import cx from "classnames";
import { debounce } from "lodash";
import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { chunk } from 'lodash';
import extractPathFromUrl from "../../utils/extractPathFromUrl";
import Button from "./../Button/Button";
import Card from "./../Card/Card";

import "./CardList.scss";

export default function CardList({ items, button, showAsSlider = false, modifier }) {
  if (!items || !items.length) return null;

  const chunkedItems = chunk(items, 4);

  const { t } = useTranslation();
  const sliderRef = useRef();
  const [selectedChunk, setSelectedChunk] = useState(0);
  const { width: sliderWidth } = useComponentSize(sliderRef);
  const [reverseButton, setReverseButton] = useState(false);

  const debouncedScrollHandler = useMemo(
    () =>
      debounce(() => {
        if (sliderRef.current) {
          const { scrollLeft, scrollWidth, offsetWidth } = sliderRef.current;
          const isAtEndOfContainer = offsetWidth + scrollLeft >= scrollWidth;
          setReverseButton(isAtEndOfContainer);
        }
      }, 17),
    [],
  );

  const handleBtnClick = useCallback(() => {
    const next = (selectedChunk + 1) % chunkedItems.length;
    setSelectedChunk(next);
  });

  useEffect(() => {
    if (sliderRef.current) {
      const left = (sliderRef.current.scrollLeft + sliderWidth) * selectedChunk;
      sliderRef.current.scrollTo({ left, behavior: "smooth" });
    }
  }, [selectedChunk]);

  useLayoutEffect(
    function () {
      if(chunkedItems.length > 1) {
        sliderRef.current.addEventListener("scroll", debouncedScrollHandler);
        return () => {
          sliderRef.current.removeEventListener("scroll", debouncedScrollHandler);
        };
      }
    },
    [sliderRef.current],
  );

  return (
    <div
      className={cx(
        "card-list",
        "card-list--overflow",
        {"card-list--overlap": !showAsSlider},
        {"card-list--slider": showAsSlider},
        {[`card-list--${modifier}`]: modifier},
        {"card-list--four": items.length === 4},
        {"card-list--more": items.length > 4},
        "wrapper wrapper--flex wrapper--max wrapper--overflow mb-xxl",
      )}
    >
      {chunkedItems.length > 1 && (
        <div className="card-list__controls">
          <Button
            className="card-list__button"
            modifier={reverseButton && "button--reversed"}
            linkText={reverseButton ? t("Start over") : t("Show more")}
            onClick={handleBtnClick}
          />
        </div>
      )}
      <div className="card-list__inner" ref={sliderRef}>
        {chunkedItems.map((group, key) => (
          <div className="card-list__group" key={key}>
            <div className="card-list__group-inner">
              {group.map((card, cardIndex) => {
                return (
                  <Card
                    key={cardIndex}
                    className="card--portrait"
                    gradient="card--gradient"
                    title={card.title}
                    quirkyTitle={card.quirkyTitle}
                    titleSize="display-2"
                    media={card.media}
                    description={card.description}
                    link={card.link}
                  />
                );
              })}
            </div>
          </div>
        ))}
      </div>
      {!!button?.url && (
        <Button
          modifier="card-list__button mt-m"
          link={extractPathFromUrl(button.url)}
          linkText={button.title}
          target={button.target}
        />
      )}
    </div>
  );
}
