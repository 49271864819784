import React from "react";
import cx from "classnames";
import Table from "../../../../components/Table/Table";

import "./WPCoreTableBlock.scss";

export function WPCoreTableBlock({ block }) {
  const {
    attributes: { align, ...restAttributes },
  } = block;

  const classes = cx(
    'table-block',
    'mt-lg',
    'mb-lg',
    'wrapper',
    {'wrapper--align-default': !align},
    {[`wrapper--align-${align}`]: align},
  );

  return (
    <div className={classes}>
      <Table
        {...restAttributes}
      />
    </div>
  );
}
