import React from "react";

import { LightboxContainer } from "../Lightbox/LightboxContainer";

import { TimelineComponent as Timeline } from "./TimelineComponent";

export function TimelineContainer({ ...props }) {
  return (
    <LightboxContainer>
      <Timeline {...props} />
    </LightboxContainer>
  );
}
