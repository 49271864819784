import cx from "classnames";
import React, { useContext, useEffect, useRef } from "react";

import {
  useTimelineLightboxItems,
  useTimelineItems,
} from "../../hooks/lightbox";
import Lightbox from "../Lightbox/Lightbox";
import { LightboxContext } from "../Lightbox/LightboxContainer";

import TimelineContent from "./TimelineContent";
import TimelineHeader from "./TimelineHeader";

import "./Timeline.scss";

export function TimelineComponent({ items, className, ...props }) {
  items = items || [];

  const timelineItems = useTimelineItems(items);
  const lightboxItems = useTimelineLightboxItems(items);
  const timeline = useRef();

  const [lightboxContext, setLightboxContext] = useContext(LightboxContext);

  useEffect(() => {
    setLightboxContext({
      ...lightboxContext,
      items: lightboxItems,
    });
    if (timeline.current) {
      timeline.current.scrollTo(Math.ceil(1000 * 1000), 0);
    }
  }, []);

  return (
    <div className={cx("timeline", className)} {...props} ref={timeline}>
      <div className="wrapper wrapper--flex wrapper--max">
        <div className="wrapper__inner">
          <TimelineHeader items={timelineItems} />
          <TimelineContent items={timelineItems} />
        </div>
      </div>
      <Lightbox />
    </div>
  );
}
