import React, { useState, useContext, createContext } from "react";

export const pageContext = createContext([{}, () => {}]);

// export const PageContextConsumer = pageContext.Consumer;

export function PageContextProvider(props) {
  const { value, ...restProps } = props;
  const { Provider } = pageContext;
  const [context, setContext] = useState(value);
  return <Provider {...props} value={[context, setContext]} />;
}

export function usePageContext() {
  return useContext(pageContext);
}

// export default usePageContext;
