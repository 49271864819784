import { Link } from "../../@whitespace/gatsby-theme-wordpress/components/Link";
import cx from "classnames";
import parse from "html-react-parser";
import React from "react";

import extractPathFromUrl from "../../utils/extractPathFromUrl";

import "./PushSquare.scss";

export default function PushSquareNumbers({ alignment, items }) {
  const ConditionallyWrapWithLink = ({ condition, wrapper, children }) => {
    if (condition) {
      return wrapper(children);
    }
    return children;
  };

  return (
    <div
      className={cx(
        "push-square push-square--numbers",
        alignment && `push-square--align-${alignment}`,
      )}
    >
      <div className="push-square__wrapper">
        {items?.map(
          (
            {
              numbers,
              link,
              body,
              title,
              backgroundColor,
              foregroundColor,
              customBackground,
            },
            index,
          ) => {
            return (
              <div className="push-square__item" key={index}>
                <div>
                  <ConditionallyWrapWithLink
                    condition={link}
                    wrapper={(children) =>
                      link.target ? (
                        <a
                          href={extractPathFromUrl(link.url)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {children}
                        </a>
                      ) : (
                        <Link to={extractPathFromUrl(link.url)}>
                          {children}
                        </Link>
                      )
                    }
                  >
                    <NumbersComponent
                      numbers={numbers}
                      backgroundColor={backgroundColor}
                      foregroundColor={foregroundColor}
                      customBackground={customBackground}
                    />
                  </ConditionallyWrapWithLink>
                  {title && (
                    <p className="push-square__item-title meta">{title}</p>
                  )}
                  {body && (
                    <div className="push-square__item-body">{parse(body)}</div>
                  )}
                </div>
              </div>
            );
          },
        )}
      </div>
    </div>
  );
}

const NumbersComponent = ({
  numbers,
  backgroundColor,
  foregroundColor,
  customBackground,
}) => {
  return (
    <div
      className={cx(
        "push-square__item-numbers",
        customBackground === "custom" ||
          `push-square__item-numbers--${backgroundColor}`,
        `push-square__item-numbers--foreground-${foregroundColor}`,
      )}
      style={
        backgroundColor === "custom"
          ? {
              "--push-square-background": `${customBackground}`,
            }
          : null
      }
    >
      {numbers.map(({ number, text, type }, index) => {
        return (
          <span className={cx(`push-square__${type}`)} key={index}>
            {number && number}
            {text && text}
          </span>
        );
      })}
    </div>
  );
};
