import React from "react";
import cx from "classnames";
// import extractPathFromUrl from "../../utils/extractPathFromUrl";
import { normalizeWordpressUrl } from "../../utils/url";
import { Link } from '../Link';
import "./WPCoreButtonBlock.scss";

const WPCoreButtonBlock = (props) => {
    const { block: { attributes } } = props;
    const {
        anchor,
        linkTarget,
        rel,
        text,
        title,
        url,
        align,
        backgroundColor,
        textColor,
        style,
        className,
    } = attributes;

    const hexTextColor = style?.color?.text;
    const hexBgColor = style?.color?.background;
    const styleAttr = {
        color: hexTextColor,
        backgroundColor: hexBgColor,
        borderColor: hexBgColor,
    };

    const styleClass = className && className.includes('is-style-') ? className : 'is-style-fill';

    const classes = cx(
        "button-block",
        { [`button-block--color-${textColor}`]: textColor},
        { [`button-block--bg-${backgroundColor}`]: backgroundColor},
        styleClass,
    );

    return (
        <div className={classes}>
            <Link
                className="button-block__link"
                to={url}
                target={linkTarget}
                rel={rel}
                title={title}
                style={styleAttr}
            >{text}<span /></Link>
        </div>
    );
};

WPCoreButtonBlock.defaultProps = {
    block: {
        attributes: {
            anchor: null,
            linkTarget: null,
            rel: null,
            text: '',
            title: null,
            href: '',
            backgroundColor: null,
            textColor: null,
            style: {
                color: {
                    backgroundColor: null,
                    text: null,
                },
            },
        },
    },
};

export default WPCoreButtonBlock;
