import { FluidImage } from "../../@whitespace/gatsby-theme-wordpress/components/Image";
import parse from "html-react-parser";
import React from "react";

import { VideoElement } from "./../MediaElement";

import "./MediaElement.scss";

export function MediaElement({
  videoLink,
  image,
  video,
  className,
  onClickImage,
  customHeight,
  ...restProps
}) {

  const caption = image?.caption ? image.caption : "";
  // Title is an attribute, parse html and convert new lines
  const title = caption.replace(/^\n+|\n+$/g, '').replace("\n", ", ").replace(/(<([^>]+)>)/gi, "");
  image = image && {
    alt: image.alt,
    base64: image.base64,
    caption: caption ? parse(caption) : '',
    height: customHeight || image.height,
    src: image.src,
    srcSet: image.srcSet,
    srcSetWebp: image.srcSetWebp,
    srcWebp: image.srcWebp,
    width: image.width,
  };
  const videoSource = video ? video.mediaItemUrl : videoLink ? videoLink : null;
  const poster = image?.src;
  const loop = Boolean(!poster && !videoLink);

  return (
    <div {...restProps}>
      {videoSource ? (
        <VideoElement
          source={videoSource}
          poster={poster}
          className={className}
          loop={loop}
          playsinline={!!loop}
          muted={!!loop}
          autoPlay={!!loop}
          {...video}
        />
      ) : (
        <FluidImage
          namespace={className}
          imgProps={{ title: title }}
          onClick={onClickImage}
          {...image}
        />
      )}
    </div>
  );
}
