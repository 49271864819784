import cx from "classnames";
import parse from "html-react-parser";
import React from "react";

import "./TextBox.scss";

export default function TextBox({ title, body, className }) {
  return (
    <div className={cx("text-box", className)}>
      {title && <div className="text-box__title">{parse(title)}</div>}
      {body && <div className="text-box__body">{parse(body)}</div>}
    </div>
  );
}
