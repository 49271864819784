export function useSuccessiveColums(column, maxWidth, minwidth) {
  let reduceValue = Math.floor(1280 / column);
  let key = maxWidth;
  let value = column > 3 ? column - 1 : column;

  let obj = new Object();
  obj[maxWidth] = value;

  if (column > 1) {
    for (let i = 1; i <= column; i++) {
      key = key - reduceValue;
      value--;

      if (key > minwidth) {
        value = value == 1 ? value + 1 : value < 1 ? 1 : value;

        obj[key] = value;
      }
    }
  }

  obj[minwidth] = 1;

  return obj;
}
