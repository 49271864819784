import { useHTMLProcessor } from "../@whitespace/gatsby-theme-wordpress/hooks/html-processor";
import { usePageContext } from "../@whitespace/gatsby-theme-wordpress/hooks/page-context";

export default function getOpenGraphContent(siteMeta, initialSeo) {
  return openGraphObject(siteMeta, initialSeo);
}

export function openGraphObject(siteMeta, initialSeo) {
  const [pageContext] = usePageContext();

  let {
    contentNode: {
      contentType: { node: { name: contentTypeName } = {} } = {},
      openGraph,
      content,
      description,
      featuredImage,
      uri,
    } = {},
  } = pageContext;

  const { processPageContent, stripHTML } = useHTMLProcessor();

  if (contentTypeName) {
    const processedContent = processPageContent(content);

    if (!description) {
      if (processedContent.preamble) {
        description = processedContent.preamble;
      } else {
        description = stripHTML(content).slice(0, 300);
      }
    }

    return {
      metaTitle: openGraph?.title
        ? openGraph.title
        : initialSeo?.title
        ? initialSeo.title
        : siteMeta.title,
      metaDescription: openGraph?.description
        ? openGraph?.description
        : description
        ? description
        : siteMeta.description,
      metaImage: openGraph?.image
        ? openGraph.image
        : featuredImage?.node
        ? featuredImage?.node
        : null,
      metaUrl: siteMeta.siteUrl + uri,
    };
  }

  return {
    metaTitle: initialSeo.title ? initialSeo.title : siteMeta.title,
    metaDescription: initialSeo.description
      ? initialSeo.description
      : siteMeta.description,
    metaImage: null,
    metaUrl: siteMeta.siteUrl,
  };
}
