import { FluidImage } from "../../@whitespace/gatsby-theme-wordpress/components/Image";
import { usePageContext } from "../../@whitespace/gatsby-theme-wordpress/hooks/page-context";
import cx from "classnames";
import React from "react";

import "./PageBackgroundImage.scss";

export default function PageBackgroundImage({ className, ...restProps }) {
  const [pageContext] = usePageContext();

  const contentNode = pageContext?.contentNode;

  if (!contentNode) {
    return null;
  }

  const { pageAppearance: { backgroundImage, backgroundImageFade } = {} } =
    contentNode;

  return (
    <FluidImage
      aria-hidden="true"
      {...backgroundImage}
      className={cx(
        "page-bg-image",
        backgroundImageFade && "has-fade",
        className,
      )}
    />
  );
}
