import React from "react";

import { Time } from "../../@whitespace/gatsby-theme-wordpress/components/Time";

import "./PubDate.scss";

export default function PubDate({ date }) {
  return (
    <p className="pub-date meta text-align-center mb-s">
      Publicerad
      <Time className="pub-date__time" date={date} />
    </p>
  );
}
