import cx from "classnames";
import React, { useContext } from "react";

import Blockquote from "../Blockquote/Blockquote";
import { LightboxContext } from "../Lightbox/LightboxContainer";
import { MediaElement } from "../MediaElement/MediaElement";
import TextBox from "../TextBox/TextBox";

export default function TimelineContent({ items, className, ...props }) {
  const [lightboxContext, setLightboxContext] = useContext(LightboxContext);

  return (
    <div
      className={cx("timeline__wrapper timeline__wrapper--content", className)}
      {...props}
    >
      {items.map(({ content }, index) => (
        <div className="timeline__item" key={index}>
          <div className="timeline__item-inner" key={index}>
            {content.map((item, itemIndex) => {
              const { type, mediaIndex, quirkyText, ...itemProps } = item;
              switch (type) {
                case "quote":
                  return (
                    <Blockquote
                      {...itemProps}
                      modifier={quirkyText ? null : "regular-text"}
                      key={itemIndex}
                    />
                  );
                case "text":
                  return <TextBox {...itemProps} key={itemIndex} />;
                default:
                  return (
                    <MediaElement
                      {...itemProps}
                      key={itemIndex}
                      onClickImage={() => {
                        setLightboxContext({
                          ...lightboxContext,
                          isOpen: true,
                          mediaIndex: mediaIndex,
                        });
                      }}
                    />
                  );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
